import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_en_graphicsmdx = graphql`
        query PageQuery_service_en_graphicsmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/graphics/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Graphics",
  "path": "/service/graphics",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "en",
  "icon": "bx-brush",
  "tags": ["front-end", "developer", "senior"],
  "experience": [{
    "title": "VR Time Travel",
    "task": "Main Developer / Graphics",
    "tags": ["developer", "graphics"],
    "outcome": ["Demonstrate Oculus Rift DK1 & DK2", "Environments of an old city in VR", "Immersive transitions with wormholes", "Stable app during the full event"],
    "link": ["https://alkmaarcentraal.nl/7841-unieke-reis-in-tijdmachine-op-alkmaarse-open-monumentendag"],
    "image_public": ["/images/service/graphics/time-vortex.gif"],
    "copyright": null
  }, {
    "title": "3D Patient Rehabilitation",
    "task": "Lead Unity Graphics",
    "tags": ["lead", "developer", "unity3d"],
    "outcome": ["Color grading via LUT", "Interactive environment features", "Skin with realistic shader (pre-HDRP)", "Bidirectional communication @ back-end"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Motion Capture Pipeline",
    "task": "Lead Developer",
    "tags": ["lead", "developer"],
    "outcome": ["Batch processing of source files", "Calculate and apply metadata + rig", "Efficient processing (thousands of) anims"],
    "link": null,
    "copyright": null
  }, {
    "title": "Sky Renderer",
    "task": "Main Developer",
    "tags": ["developer"],
    "outcome": ["Volumetric clouds", "Rendering of sun, moon & godrays", "Physically based sky rendering", "Rayleigh and Mie scattering", "Integration with Unity 3D (v4 & 5)"],
    "link": null,
    "image": ["./images/graphics/cb-sky-clouds-01.jpg", "./images/graphics/cb-sky-clouds-02.jpg"],
    "copyright": "Cheesebyte"
  }]
};
const layoutProps = {
  PageQuery_service_en_graphicsmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "unity-for-pipelines-unreal-graphics",
      "style": {
        "position": "relative"
      }
    }}>{`Unity for pipelines, unreal graphics`}<a parentName="h1" {...{
        "href": "#unity-for-pipelines-unreal-graphics",
        "aria-label": "unity for pipelines unreal graphics permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`In the late nineties, I wrote small 3D engines as a hobby. Afterwards I gained professional experience in setting up complex pipelines to support these systems.`}</p>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}>{`Skills`}<a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`8+ years of experience with Unity 3D`}</li>
      <li parentName="ul">{`10+ years of experience with image processing`}</li>
      <li parentName="ul">{`12+ years of experience with 3D development`}</li>
      <li parentName="ul">{`History as technical artist in film + `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Motion_capture"
        }}>{`mocap`}</a></li>
    </ul>
    <h2 {...{
      "id": "technical",
      "style": {
        "position": "relative"
      }
    }}>{`Technical`}<a parentName="h2" {...{
        "href": "#technical",
        "aria-label": "technical permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Direct3D, OpenGL (Vulkan: not yet)`}</li>
      <li parentName="ul">{`Math (linear algebra, geo) + basic physics`}</li>
      <li parentName="ul">{`Acceleration structures for better performance`}</li>
      <li parentName="ul">{`Computer vision + ML (OpenCV, Tensorflow, ML.NET)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      